// extracted by mini-css-extract-plugin
export var projects = "Projects-module--projects--H8Rua";
export var gutenberg = "Projects-module--gutenberg--1eJZ4";
export var container = "Projects-module--container--2vXLC";
export var filters = "Projects-module--filters--3GWW8";
export var filterBackground = "Projects-module--filterBackground--1Q4py";
export var filterForeground = "Projects-module--filterForeground--1QCgx";
export var list = "Projects-module--list--3tQZw";
export var listItem = "Projects-module--listItem--2Su6X";
export var link = "Projects-module--link--1iZS3";
export var imageWrapper = "Projects-module--imageWrapper--FUL5j";
export var iconWrapper = "Projects-module--iconWrapper--3ipr2";
export var overlay = "Projects-module--overlay--2V9CQ";
export var services = "Projects-module--services--1dgRs";
export var service = "Projects-module--service--3NDht";
export var heading = "Projects-module--heading--13iAX";
export var icon = "Projects-module--icon--19hMH";
export var buttonWrapper = "Projects-module--buttonWrapper--2RAlT";
export var partnersBlockWrapper = "Projects-module--partnersBlockWrapper--312hs";
export var bounce = "Projects-module--bounce--1oFNK";
export var heartbeat = "Projects-module--heartbeat--3ejq9";
export var ellipsisOne = "Projects-module--ellipsisOne--QXAzM";
export var ellipsisTwo = "Projects-module--ellipsisTwo--p7c2Y";
export var ellipsisThree = "Projects-module--ellipsisThree--37d0T";
export var top = "Projects-module--top--bipBq";
export var topTwo = "Projects-module--topTwo--s63Tu";
export var center = "Projects-module--center--CaoKL";
export var centerTwo = "Projects-module--centerTwo--_yCTd";
export var bottom = "Projects-module--bottom--1bSuQ";
export var bottomTwo = "Projects-module--bottomTwo--NFCbZ";