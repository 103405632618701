import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { PageProps } from "gatsby";
import { Ref } from "react";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import Button from "@Js/Component/Button";
import EyeIcon from "@Js/Component/Icon/EyeIcon";
import Gutenberg from "@Js/Component/Gutenberg";
import Heading from "@Js/Component/Heading";
import PartnersBlock from "@Js/Component/Block/PartnersBlock";
import Service from "@Js/Type/Service";

import * as classNames from "@Css/Component/Page/Projects.module.scss";

/**
 * @type AllWordpressProjectData
 */
type AllWordpressProjectData = {
    allWordpressProject: {
        edges: {
            node: {
                details: {
                    services: Service[];
                };
                featuredImage?: {
                    node: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
                id: string;
                title: string;
                uri: string;
            };
        }[];
    };
};

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content?: string;
        extraContent?: string;
        seo: {
            canonical: string;
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphAuthor: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            schema: {
                raw: string;
            };
            title: string;
        };
    };
};

/**
 * @type ProjectsProps
 */
export type ProjectsProps = PageProps;

/**
 * @const Projects
 */
const Projects = (props: ProjectsProps, ref: Ref<HTMLElement>) => {
    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const [service, setService] = useState<Service | null>(null);

    const data: AllWordpressProjectData & WordpressPageData = useStaticQuery(graphql`
        query {
            allWordpressProject(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        details {
                            services
                        }
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(aspectRatio: 1, quality: 100)
                                    }
                                }
                            }
                        }
                        id
                        title
                        uri
                    }
                }
            }
            wordpressPage(isProjectsPage: { eq: true }) {
                content
                extraContent
                seo {
                    canonical
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphAuthor
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    schema {
                        raw
                    }
                    title
                }
            }
        }
    `);

    const getService = (service: Service) => {
        switch (service) {
            case "e_commerce":
                return t("E-commerce");

            case "web_application":
                return t("Web Application");

            case "website":
                return t("Website");
        }
    };

    const onFilterButtonClick = (service: Service | null) => () => {
        setService(service);
    };

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    return (
        <>
            <Seo { ...data.wordpressPage.seo } />
            <section className={ classNames.projects } ref={ ref }>
                { data.wordpressPage.content && (
                    <Gutenberg className={ classNames.gutenberg } content={ data.wordpressPage.content } />
                ) }
                <section className={ classNames.projects }>
                    <div className={ classNames.container }>
                        <div className={ classNames.filters }>
                            <Button
                                active={ service == null }
                                backgroundProps={{ className: classNames.filterBackground }}
                                foregroundProps={{ className: classNames.filterForeground }}
                                onClick={ onFilterButtonClick(null) }
                                variant="outlined"
                            >
                                { t("Show everything") }
                            </Button>
                            <Button
                                active={ service == "e_commerce" }
                                backgroundProps={{ className: classNames.filterBackground }}
                                foregroundProps={{ className: classNames.filterForeground }}
                                onClick={ onFilterButtonClick("e_commerce") }
                                variant="outlined"
                            >
                                { t("E-commerce") }
                            </Button>
                            <Button
                                active={ service == "web_application" }
                                backgroundProps={{ className: classNames.filterBackground }}
                                foregroundProps={{ className: classNames.filterForeground }}
                                onClick={ onFilterButtonClick("web_application") }
                                variant="outlined"
                            >
                                { t("Web Applications") }
                            </Button>
                            <Button
                                active={ service == "website" }
                                backgroundProps={{ className: classNames.filterBackground }}
                                foregroundProps={{ className: classNames.filterForeground }}
                                onClick={ onFilterButtonClick("website") }
                                variant="outlined"
                            >
                                { t("Websites") }
                            </Button>
                        </div>
                        { data.allWordpressProject.edges.length > 0 && (
                            <ul className={ classNames.list }>
                                { data.allWordpressProject.edges.map((edge) => (
                                    <>
                                        { (!(service) || edge.node.details.services.includes(service)) && (
                                            <li className={ classNames.listItem } key={ edge.node.id }>
                                                <Link className={ classNames.link } to={ edge.node.uri }>
                                                    { edge.node.featuredImage && (
                                                        <GatsbyImage
                                                            alt={ edge.node.title }
                                                            image={ edge.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                                                        />
                                                    ) }
                                                    <div className={ classNames.overlay } />
                                                    <section className={ classNames.container }>
                                                        <Heading className={ classNames.services } element="h2">
                                                            { edge.node.details.services.map((service, index) => (
                                                                <span className={ classNames.service } key={ service }>
                                                                { getService(service) }
                                                                    { !(index == edge.node.details.services.length - 1) ? ", " : "" }
                                                            </span>
                                                            )) }
                                                        </Heading>
                                                        <Heading className={ classNames.heading }>{ edge.node.title }</Heading>
                                                    </section>
                                                    <div className={ classNames.iconWrapper }>
                                                        <EyeIcon className={ classNames.icon } />
                                                    </div>
                                                </Link>
                                            </li>
                                        ) }
                                    </>
                                )) }
                            </ul>
                        ) }
                    </div>
                </section>
                <div className={ classNames.partnersBlockWrapper }>
                    <PartnersBlock content={ data.wordpressPage.extraContent } />
                </div>
            </section>
        </>
    );
};

export default forwardRef(Projects);
