import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Ref } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import Gutenberg from "@Js/Component/Gutenberg";

import * as classNames from "@Css/Component/Block/PartnersBlock.module.scss";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        otherSettings: {
            partners: {
                partners: {
                    partnersImage: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                    partnersName: string;
                    partnersUrl: string;
                }[];
            };
        };
    };
};

/**
 * @type PartnersBlockProps
 */
export type PartnersBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children"> & {
    content?: string;
};

/**
 * @const PartnersBlock
 */
const PartnersBlock = (props: PartnersBlockProps, ref: Ref<HTMLElement>) => {
    const {
        className,
        content,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const data: WordpressData = useStaticQuery(graphql`
        query {
            wordpress {
                otherSettings {
                    partners {
                        partners {
                            partnersName
                            partnersUrl
                            partnersImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(quality: 100)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.partnersBlock, className) } ref={ ref }>
            <div className={ classNames.container }>
                { content && (
                    <Gutenberg className={ classNames.gutenberg } content={ content } />
                ) }
                <ul className={ classNames.list }>
                    { data.wordpress.otherSettings.partners.partners.map((partner) => (
                        <li className={ classNames.listItem } key={ partner.partnersName }>
                            <div className={ classNames.partnerWrapper }>
                                <div className={ classNames.partner }>
                                    <GatsbyImage
                                        alt={ partner.partnersName }
                                        image={ partner.partnersImage.localFile.childImageSharp.gatsbyImageData }
                                    />
                                </div>
                            </div>
                        </li>
                    )) }
                </ul>
            </div>
        </section>
    );
};

export default forwardRef(PartnersBlock);
